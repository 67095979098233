/* Add CSS styles for your layout here */
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    /*overflow: hidden; /* Prevents scrolling */
    background-color: black; /* Set the background color to black */
    color: white; /* Set text color to white */
}

.container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
}

.left-panel {
    flex: 1; /* Take up half of the available space */
    padding: 20px;
    
}

.blurb {
    text-align: left;
    padding-left: 50px;
}

.right-panel {
    flex: 2; /* Adjust this value to make the image 2/3 of the screen */
    padding: 20px;
    overflow: hidden; /* Prevents the image from overflowing the viewport */
    text-align: center; /* Center the image horizontally */
}

/* Additional styles for mobile layout */
@media screen and (max-width: 600px) {
    .container {
        flex-direction: column-reverse; /* Switch to column layout and reverse the order */
    }

    .left-panel, .right-panel {
        flex: 1; /* Take up full width */
        order: unset; /* Reset the order property */
    }

    .blurb {
        text-align: left;
        padding-left: 20px;
    }

    .right-panel {
        margin-top: 0px; /* Add some space between panels */
        padding: 0px;
    }

    /* Image blur with a static gradient at the bottom */
    .faded-image {
        width: 100%;
        height: auto; /* Maintain aspect ratio */
        display: block;
        mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 15%, rgba(0, 0, 0, 0) 100%); /* Gradient mask to fade only the first 15% */
    }
    .header {
        display: none;
    }

    .headerMobile {
        position: absolute;
        top: 20px; /* Adjust this value to move it down */
        left: 0;
        width: 100%;
        z-index: 3; /* Ensure it's above other elements */
    }
  
}


@media screen and (min-width: 601px) {
    .headerMobile{
        display: none;
    }
}
/* Your existing styles below this line */
/* ... */

.MainContainer {
    display: flex;
    flex-direction: column;
}

.Container {
    /* Your existing styles for the content container */
    /* For example: */
    background-color: #333;
    color: #fff;
    padding: 20px;
}

.FormContainer {
    width: 100vw; /* 100% of viewport width */
            height: 100vh; /* 100% of viewport height */
            margin: 0;
            padding: 0;
            border: none; /* Remove border from the iframe */
    background-color: black; /* Set the background color to black */
    color: white; /* Set text color to white */
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.Mutebtn{
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    padding: 10px 20px;
    border: 0.5px solid white; /* Add a 2px white solid border */
    border-radius: 30px;
    cursor: pointer;
    backdrop-filter: blur(10px);
  }

  .button {
    font-size: large;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    padding: 10px 20px;
    border: 0.5px solid white; /* Add a 2px white solid border */
    border-radius: 30px;
    cursor: pointer;
    backdrop-filter: blur(10px);
}

  
  .button:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  
  
  

  
  
  



.Form {
    width: 100%;
    height: 100%;
    border: none; /* Remove border from the iframe */
}


.header-left {
    flex: 1; /* Take up half of the available space */
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative; /* Add position relative to allow pseudo-element */
}

.header {
    margin-bottom: 20px; /* Add spacing between header and left panel */
}



.image-container {
    margin-top: -20px; /* Move the image up further by adjusting this value */
    margin-right: -20px;
}

.image {
    max-width: 100%;
    height: auto;
    display: block;
    width: 100%; /* Ensure the image takes the full width of the right panel */
}

.Line{
  width: 0.12%; /* Adjust the width as needed */
  height: 93%;
  background-color: white;
  position: absolute;
  top: 3%;
  bottom: 0%;
  left: 33%; /* Position the line in the middle of the container */
 
  transform: translateX(-50%);
  z-index: 1; /* Ensure the line is displayed between the panels */

}
.hidden {
    display: none;
  }
  
  .visible {
    display: block;
  }
  

.faded-image {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    display: block;

    /* Default gradient mask to fade the bottom of the image */
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));

    /* Adjust the gradient for desktop screens */
    @media screen and (min-width: 600px) {
        mask-image: linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    }
}



  @import url(

    https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
    
     
    
    * {
    
                    margin: 0;
    
                    padding: 0;
    
                    box-sizing: border-box;
    
                    font-family: "Poppins", sans-serif;
    
    }
    
     
    
    body {
    
                    background-color: #000000;
    
                    background-image: linear-gradient(bottom,
    
                                                                                                                                                    #000058 60%,
    
                                                                                                                                                    #000033 40%);
    
    }
    
     
    
    header {
    
                    width: 100%;
    
                    height: 90px;
    
                    position: absolute;
    
                    z-index: 100;
    
                    display: flex;
    
                    flex-direction: column;
    
                    align-items: center;
    
                    justify-content: center;
    
                    background-color: #ffffff;
    
    }
    
     
    
    .heading {
    
                    color: green;
    
    }
    
     
    
    .title {
    
                    font-weight: 400;
    
                    letter-spacing: 1.5px;
    
    }
    
     
    
    ul.fireflies {
    
                    list-style: none;
    
    }
    
     
    
    li {
    
                    border-radius: 50%;
    
                    background-color: rgb(255, 255, 73);
    
                    background-image: radial-gradient(rgb(249, 206, 36) 5%,
    
                                                                                                                                                    rgb(254, 179, 4) 25%,
    
                                                                                                                                                    rgb(252, 191, 7) 60%);
    
                    box-shadow: 0 0 5px 2px rgb(250, 193, 93),
    
                                                                    0 0 20px 10px rgb(255, 228, 140),
    
                                                                    0 0 40px 15px rgb(255, 219, 41);
    
                    height: 5px;
    
                    width: 5px;
    
                    top: -20px;
    
                    position: absolute;
    
                    animation: leftright 24s infinite cubic-bezier(0.39, 0, 0.63, 1),
    
                                    updown 8s infinite 1.25s cubic-bezier(0.39, 0, 0.63, 1),
    
                                    blinking 3s infinite;
    
    }
    
     
    
    /* first 10 */
    
    li:nth-of-type(1) {
    
                    animation-delay: 0.5s;
    
                    animation-duration: 120s, 120s, 1s;
    
                    animation-fill-mode: backwards, backwards;
    
    }
    
     
    
    li:nth-of-type(2) {
    
                    animation-delay: 0.5s;
    
                    animation-duration: 120s, 120s, 1s;
    
    }
    
     
    
    li:nth-of-type(3) {
    
                    animation-delay: 1.5s;
    
                    animation-duration:  120s, 80s, 1s;
    
                    animation-fill-mode: backwards, backwards;
    
    }
    
     
    
    li:nth-of-type(4) {
    
                    animation-delay: 15s;
                    animation-duration: 25s, 7s,2s;
    
    }
    
     
    
    li:nth-of-type(5) {
    
                    animation-delay: 15s;
                    animation-duration: 25, 5s, 2.5s;
    
    }
    
     
    
    li:nth-of-type(6) {
    
                    animation-delay: 2.2s;
    
                    animation-duration: 80s, 120s, 1.5s;
    
                    animation-fill-mode: backwards, backwards;
    
    }
    
     
    
    li:nth-of-type(7) {
    
                    animation-delay: 0.9s;
    
                    animation-duration: 70s, 80s, 3s;
    
    }
    
     
    
    li:nth-of-type(8) {
    
                    animation-delay: 1.6s;
    
                    animation-duration: 100s, 120s, 2.5s;
    
                    animation-fill-mode: backwards, backwards;
    
    }
    
     
    
    li:nth-of-type(9) {
    
                    animation-delay: 1.8s;
    
                    animation-duration: 60s, 88s, 1s;
    
    }
    
     
    
    li:nth-of-type(10) {
    
                    animation-delay: 0.3s;
    
                    animation-duration: 87s, 90s, 1.5s;
    
                    animation-fill-mode: backwards;
    
    }
    
     
    
    /* last 10 */
    
    li:nth-of-type(11) {
    
                    animation-delay: 3s;
    
                    animation-duration: 60s, 78s, 1s;
    
                    animation-fill-mode: backwards, backwards;
    
    }
    
     
    
    li:nth-of-type(12) {
    
                    animation-delay: 0.5s;
    
                    animation-duration: 85s, 80s, 1s;
    
    }
    
     
    
    li:nth-of-type(13) {
    
                    animation-delay: 1.5s;
    
                    animation-duration: 75s, 66s, 1s;
    
                    animation-fill-mode: backwards, backwards;
    
    }
    
     
    
    li:nth-of-type(14) {
    
                    animation-delay: 2.5s;
    
                    animation-duration: 87s, 75s, 1s;
    
    }
    
     
    
    li:nth-of-type(15) {
    
                    animation-delay: 0.3s;
    
                    animation-duration: 69s, 85s, 1s;
    
    }
    
     
    
    li:nth-of-type(16) {
    
                    animation-delay: 10s;
    
                    animation-duration: 30s, 77s, 1s;
    
                    animation-fill-mode: backwards,forwards;
    
    }
    
     
    
    li:nth-of-type(17) {
    
                    animation-delay: 0.9s;
    
                    animation-duration: 65s, 95s,1s;
    
    }

    li:nth-of-type(18) {
    
                    animation-delay: 1.6s;
    
                    animation-duration: 59s, 63s, 1s;
    
                    animation-fill-mode: backwards, backwards;
    
    }
    
    li:nth-of-type(19) {
    
                    animation-delay: 1.8s;
    
                    animation-duration: 88s, 79s,1s;
    
    }
    
     
    
    li:nth-of-type(20) {
    
                    animation-delay: 0.5s;
    
                    animation-duration: 59s, 75s, 1s;
    
                    animation-fill-mode: backwards, backwards;
    
    }
    
     
    
    /* animations */
    
     
    
    /* It will create the left right movement */
    
    @keyframes leftright {
    
     
    
                    0%,
    
                    100% {
    
                                    left: 80%;
    
                    }
    
     
    
                    16.666% {
    
                                    left: 95%;
    
                    }
    
     
    
                    33.333% {
    
                                    left: 10%;
    
                    }
    
     
    
                    50% {
    
                                    left: 60%;
    
                    }
    
     
    
                    66.666% {
    
                                    left: 70%;
    
                    }
    
     
    
                    83.333% {
    
                                    left: 5%;
    
                    }
    
    }
    
     
    
    /* It will create the up down movement */
    
    @keyframes updown {
    
     
    
                    0%,
    
                    100% {
    
                                    top: 10px;
    
                    }
    
     
    
                    25% {
    
                                    top: 90%;
    
                    }
    
     
    
                    50% {
    
                                    top: 50%;
    
                    }
    
     
    
                    75% {
    
                                    top: 95%;
    
                    }
    
    }
    
     
    
    /* It will create the blinking effect */
    
    @keyframes blinking {
    
     
    
                    0%,
    
                    100% {
    
                                    box-shadow: 0 0 3px 1px rgb(250, 193, 93),
    
                                                                                    0 0 5px 2px rgb(255, 242, 63, 0.7),
    
                                                                                    0 0 10px 5px rgb(255, 219, 41);
    
                                    height: 0;
    
                                    width: 0;
    
                    }
    
     
    
                    60% {
    
                                    box-shadow: 0 0 2px 1px rgb(250, 193, 93),
    
                                                                                    0 0 5px 3px rgb(255, 228, 140, 0.7),
    
                                                                                    0 0 40px 15px rgb(255, 219, 41);
    
                    }
    
     
    
                    80% {
    
                                    box-shadow: 0 0 0px 0px rgb(250, 193, 93),
    
                                                                                    0 0 0px 0px rgb(255, 228, 140),
    
                                                                                    0 0 0px 0px rgb(255, 219, 41);
    
                    }
    
    }

